import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import store from 'redux-store';
import { history } from 'routes/history';
import { ViewportContextProvider, useSetViewportHeightCSS, AlertContainer } from '@umai/common';
import { DrawerProvider } from 'contexts/drawer';
import { PopoverLibProvider } from 'contexts/popover';
import { AppProvider } from 'contexts/app';
import { client } from 'utils/query-client';
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';
import { NotificationsContextProvider } from 'modules/NotificationCenter/context';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import NotificationAudio from 'components/shared/NotificationAudio';
import CacheBuster from 'components/shared/CacheBuster';
import NetworkStatus from 'components/shared/NetworkStatus';
import BootstrapApp from 'modules/Bootstrap';
import PaymentProfile from 'modules/Partner/components/PaymentProfile';
import QueueFunnel from 'modules/QueueFunnel';
import CallPopup from 'modules/IncomingCall/components/CallPopup';
import Routes from 'routes';

const App = () => {
  useSetViewportHeightCSS();

  return (
    <React.StrictMode>
      <QueryClientProvider client={client}>
        <Provider store={store}>
          <ViewportContextProvider>
            <ErrorBoundary>
              <AlertContainer />
              <NetworkStatus />
              <PopoverLibProvider>
                <NotificationsContextProvider>
                  <BootstrapApp />
                  {/**
                   *  Not using BrowserRouter because it ignores the history prop as it handles the history automatically,
                   *  So using Router with history here to access the history outside of a App, .i.e in Timeline -> Item components.
                   */}
                  <Router history={history}>
                    <CacheBuster />
                    <DrawerProvider>
                      <AppProvider>
                        <CallPopup />
                        <PaymentProfile />
                        <Routes />
                        <QueueFunnel />
                      </AppProvider>
                    </DrawerProvider>
                  </Router>
                  <NotificationAudio
                    id={ORDER_NOTIFICATION_AUDIO_ID}
                    src={`${process.env.PUBLIC_URL}/order-notifcation-sound.mp3`}
                  />
                </NotificationsContextProvider>
              </PopoverLibProvider>
            </ErrorBoundary>
          </ViewportContextProvider>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  );
};

export default App;
