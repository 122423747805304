/* eslint-disable no-console */
import { ORDER_NOTIFICATION_AUDIO_ID } from 'modules/Order/constants';

type NotificationOptions = {
  body?: string;
  icon?: string;
  tag?: string;
  badge?: string;
  sound?: string;
  data?: any;
  lang?: string;
  dir?: 'auto' | 'ltr' | 'rtl';
  vibrate?: number[];
  renotify?: boolean;
  requireInteraction?: boolean;
  silent?: boolean;
};

// TODO: Not sure whether we need this class
export default class PushNotification {
  private sound: HTMLAudioElement | null;

  private notification: Notification | null;

  constructor(audioId: string = ORDER_NOTIFICATION_AUDIO_ID) {
    this.notification = null;
    this.sound = document.getElementById(audioId) as HTMLAudioElement;
    if (!this.sound) {
      console.warn(`Audio element with id '${audioId}' not found.`);
    }
  }

  async playSound(): Promise<void> {
    if (this.sound) {
      this.sound.loop = true;
      this.sound.muted = false;
      const isPlaying =
        this.sound.currentTime > 0 &&
        !this.sound.paused &&
        !this.sound.ended &&
        this.sound.readyState > 2;

      if (!isPlaying) {
        try {
          await this.sound.play();
        } catch (error) {
          console.error('Error playing sound: ', error);
          this.pauseSound();
        }
      }
    }
  }

  pauseSound(): void {
    if (this.sound) {
      this.sound.loop = false;
      this.sound.pause();
      this.sound.currentTime = 0;
    }
  }

  private onShowNotification = (): void => {
    console.debug('Notification is shown!');
    this.playSound();
  };

  private onCloseNotification = (): void => {
    console.debug('Notification is closed!');
    this.pauseSound();
  };

  private onClickNotification = (): void => {
    console.debug('Notification was clicked!');
    if (this.notification) {
      this.notification.close();
    }
    if (window?.focus) window.focus();
    this.pauseSound();
  };

  private onErrorNotification = (): void => {
    console.error('Error showing notification. You may need to request permission.');
  };

  private async requestPermission(): Promise<boolean> {
    if (Notification.permission === 'granted') {
      return true;
    }

    if (Notification.permission !== 'denied') {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    }

    return false;
  }

  private createNotification(title: string, options: NotificationOptions): void {
    if (this.notification) {
      this.notification.close();
    }

    this.notification = new Notification(title, options);
    this.notification.onshow = this.onShowNotification;
    this.notification.onclose = this.onCloseNotification;
    this.notification.onclick = this.onClickNotification;
    this.notification.onerror = this.onErrorNotification;
  }

  async show(title: string, options: NotificationOptions = {}): Promise<void> {
    try {
      if (!('Notification' in window)) {
        console.warn('This browser does not support desktop notification');
        return;
      }

      const permissionGranted = await this.requestPermission();

      if (permissionGranted) {
        this.createNotification(title, options);
      } else {
        console.warn('Notification permission has been denied by the user');
      }
    } catch (error) {
      console.error('Something went wrong with show notification:', error);
    }
  }
}
