import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from '@umai/common';
import { COLORS } from 'constants/colors';
import LoyaltyStatusIcon from 'components/shared/LoyaltyStatusIcon';
import BlacklistIcon from 'components/icons/BlacklistIcon';
import './GuestName.styles.scss';

interface GuestNameProps {
  name?: string;
  isBlacklisted?: boolean;
  hasLoyalty?: boolean;
  className?: string;
  prefixContent?: React.ReactNode;
  isWhite?: boolean;
  hasTooltip?: boolean;
}

const GuestName = ({
  name,
  className,
  isBlacklisted = false,
  hasLoyalty = false,
  prefixContent,
  isWhite = false,
  hasTooltip = false,
}: GuestNameProps) => {
  const renderBlacklistIcon = () => {
    if (hasTooltip) {
      return (
        <Tooltip
          // @ts-expect-error TS(2322): Type '{ children: Element; content: string; positi... Remove this comment to see the full error message
          content="This guest is blacklisted"
          position="top"
          className="blacklist-tooltip"
          triggerComponent={
            <BlacklistIcon size={19} color={isWhite ? COLORS.WHITE : COLORS.DANGER} />
          }
          triggerClassName="blacklist-icon"
          zIndex={10}
        >
          <span className="blacklist-tooltip">
            This guest has been blacklisted at your venue. They can still make reservations and
            online purchases, but you can decide how to manage blacklisted guests on your own.
          </span>
        </Tooltip>
      );
    }
    return (
      <BlacklistIcon
        className="blacklist-icon"
        size={19}
        color={isWhite ? COLORS.WHITE : COLORS.DANGER}
      />
    );
  };

  return (
    <div
      className={classnames('guest-name', className, {
        'is-blacklisted': isBlacklisted,
        'is-white': isWhite,
      })}
    >
      {prefixContent || null}
      {/* The loyalty status is irrelevant when the guest is blacklisted, so we only show the blacklist icon when blacklisted */}
      {isBlacklisted ? (
        renderBlacklistIcon()
      ) : (
        <LoyaltyStatusIcon hasLoyalty={hasLoyalty} isWhite={isWhite} />
      )}
      {name}
    </div>
  );
};

GuestName.propTypes = {
  name: PropTypes.string.isRequired,
  isBlacklisted: PropTypes.bool,
  hasLoyalty: PropTypes.bool,
  className: PropTypes.string,
  prefixContent: PropTypes.node,
  isWhite: PropTypes.bool,
  hasTooltip: PropTypes.bool,
};

export default GuestName;
